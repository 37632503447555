var mapBoxApiUrl = "https://api.mapbox.com/search/geocode/v6/forward";

$.onmount('[data-component=shipping-address]', function() {
  if (SKIP_MAPBOX_CALL) return;

  var $el = $(this);
  var $country = $('select[name*=country]');
  var $zip = $el.find('input[name*=zip]');
  var debounceTimeout = null;
  var controller = new AbortController();

  $zip.on('input', function() {
    clearTimeout(debounceTimeout);
    controller.abort(); // Abort the previous request
    controller = new AbortController(); // Create a new AbortController

    debounceTimeout = setTimeout(function() {
      var zip = $zip.val().trim();
      var country = $country.val();

      if (zip && country) {
        fetchGeoCoding(zip, country, controller);
      }
    }, 500); // Adjust the timeout duration as needed (500 ms in this case)
  });
});

function isSelectized($element) {
  return $element.hasClass('selectized') || $element.hasClass('selectize-control');
}

function fetchGeoCoding(postcode, country, controller) {
  $.ajax({
    url: mapBoxApiUrl,
    method: 'GET',
    data: {
      postcode: postcode,
      country: country,
      access_token: MAPBOX_API_KEY
    },
    headers: {
        'Accept': 'application/json'
    },
    beforeSend: function(xhr) {
      controller.signal.addEventListener('abort', function() {
        xhr.abort(); // Use xhr.abort() instead of jqXHR.abort()
      });
    },
    success: function(response) {
      var targetFeature = null;

      $.each(response.features, function(index, feature) {
        if (feature.properties.context.country.country_code === country && feature.properties.name === postcode) {
          targetFeature = feature;
          return false; // Exit the loop once the feature is found
        }
      });

      if (targetFeature) {
        var context = targetFeature.properties.context
        var city = context.place.name
        var state = context.region.name
        var state_code = context.region.region_code

        if(city != ""){
          $("[id$='_form_city']").val(city);
        }

        if(state != ""){
          $("[id$='_form_state']").val(state);
        }

        if(state_code != ""){
          var $elements = $('[id^="states-code-"]'); // Select all elements with IDs starting with 'states-code-'
          $elements.each(function() {
            var $element = $(this);
            if (isSelectized($element)) {
              console.log(state_code)
              var selectize = $element[0].selectize;
              selectize.setValue(state_code); // Assuming state_code is defined and you want to set the same value for all
            }
          });
        }
      }
    }
  });
}
